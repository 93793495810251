import { LoginHeader } from "components/organisms/header/LoginHeader";
import { LoginTemplates } from "components/templates/LoginTemplates";

const LoginScreen = () => {
  return (
    <>
      <LoginHeader />
      <div className="min-h-[calc(100vh-73px)] px-6 bg-slate-100 flex justify-center items-center">
        <LoginTemplates />
      </div>
    </>
  );
};

export default LoginScreen;
