import { CookingList } from "components/organisms/cooking-list";
import { SettingDialog } from "components/organisms/dialog";
import { KitchenHeader } from "components/organisms/header";
import { KitchenNavigation } from "components/organisms/navigation";
import { KitchenTemplates } from "components/templates";
import { useBlur } from "hooks/UseBlur";
import Authn from "internal/base/middleware/authn";
import { SocketProvider } from "hooks/useSocket";
import { useState } from "react";
import { ORDER_COOKING, ORDER_START } from "screens/home/data";
import { toastMessageKey } from "lib/global";
import { ChecklistIcon } from "components/icons/Checklist";
import { toast } from "sonner";
import {
  areAllBumpedInOrder,
  areAllDeletedInOrder,
  isAnyBumpedInOrder,
} from "lib/utils";
import { useSetting } from "hooks/useSetting";

const HomeScreen = () => {
  const { setBlur } = useBlur();
  const { settingForm } = useSetting();
  const [selectedSummary, setSelectedSummary] = useState<number | null>(null);

  const [orders, setOrders] = useState<Order[]>([
    ...ORDER_START,
    ...ORDER_COOKING,
  ]);

  const deleteOrder = (orderData: Order) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => {
        if (order.id === orderData.id) {
          return { ...order, isDeleted: true };
        }
        return order;
      }),
    );
  };

  const bumpOrder = (orderData: Order) => {
    const isAnyBumpedPrev = isAnyBumpedInOrder(orderData);
    setOrders((prevOrders: Order[]) =>
      prevOrders.map((order: Order) => {
        if (order.id === orderData.id) {
          return {
            ...order,
            ...(!isAnyBumpedPrev && {
              bumpedAt: Date.now(),
            }),
            isShowAtStart: false,
            isShowAtCooking: true,
            menus: order.menus?.map((menu: Menu) => ({
              ...menu,
              subMenus: menu.subMenus?.map((subMenu: subMenus) => ({
                ...subMenu,
                isBumped: true,
              })),
            })),
          };
        }
        return order;
      }),
    );
  };

  const recallOrder = (orderData: Order) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => {
        if (order.id === orderData.id) {
          return {
            ...order,
            bumpedAt: null,
            isShowAtStart: true,
            isShowAtCooking: false,
            menus: order.menus?.map((menu: Menu) => ({
              ...menu,
              subMenus: menu.subMenus?.map((subMenu: subMenus) => ({
                ...subMenu,
                isBumped: false,
              })),
            })),
          };
        }
        return order;
      }),
    );
  };

  const handleClickActionMenu = (
    type: ActionMenuType,
    order: Order,
    section: "START" | "COOKING",
  ) => {
    toast.success(`Order has been ${toastMessageKey[type]} successfully`, {
      position: "top-center",
      icon: <ChecklistIcon />,
    });

    setBlur(null);

    if (type === "print") {
      return;
    }

    if (type === "bump") {
      if (section === "COOKING") {
        deleteOrder(order);
        return;
      }

      bumpOrder(order);
      return;
    }

    if (type === "recall") {
      recallOrder(order);
      return;
    }
  };

  const toggleBumpItemStart = (
    orderId: number,
    subMenuId: number,
    isBumped: boolean,
    type?: string,
  ) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => {
        const isAnyBumpedPrev = isAnyBumpedInOrder(order);

        if (order.id === orderId) {
          const updatedMenus = order.menus.map((menu) => {
            const updatedSubMenus = menu.subMenus.map((subMenu) => {
              if (subMenu.id === subMenuId) {
                return { ...subMenu, isBumped };
              }
              return subMenu;
            });
            return { ...menu, subMenus: updatedSubMenus };
          });

          const isAnyBumped = isAnyBumpedInOrder({
            ...order,
            menus: updatedMenus,
          });

          const isAllBumped = areAllBumpedInOrder({
            ...order,
            menus: updatedMenus,
          });

          if (isAllBumped || (type === "recall" && !isAnyBumped)) {
            setBlur(null);
          }

          return {
            ...order,
            ...(!isAnyBumpedPrev && {
              bumpedAt: isAnyBumped ? Date.now() : null,
            }),
            menus: updatedMenus,
            isShowAtStart: !isAllBumped,
            isShowAtCooking: isAnyBumped,
          };
        }
        return order;
      }),
    );
  };

  const toggleBumpItemCooking = (
    orderId: number,
    subMenuId: number,
    isBumped: boolean,
  ) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => {
        if (order.id === orderId) {
          const updatedMenus = order.menus.map((menu) => {
            const updatedSubMenus = menu.subMenus.map((subMenu) => {
              if (subMenu.id === subMenuId) {
                return { ...subMenu, isDeleted: isBumped };
              }
              return subMenu;
            });
            return { ...menu, subMenus: updatedSubMenus };
          });

          if (
            areAllDeletedInOrder({
              ...order,
              menus: updatedMenus,
            })
          ) {
            setBlur(null);
          }

          return {
            ...order,
            menus: updatedMenus,
          };
        }

        return order;
      }),
    );
  };

  const handleClickActionItem = (
    params: ActionMenu,
    section: "START" | "COOKING",
  ) => {
    const { isBumped, order, subMenuId, type } = params;
    toast.success(`Item has been ${toastMessageKey[type]} successfully`, {
      position: "top-center",
      icon: <ChecklistIcon />,
    });

    if (type === "print") {
      return;
    }

    if (type === "bump") {
      if (section === "START") {
        toggleBumpItemStart(order.id, subMenuId, isBumped);
        return;
      } else if (section === "COOKING") {
        toggleBumpItemCooking(order.id, subMenuId, isBumped);
        return;
      }
    }

    if (type === "recall") {
      toggleBumpItemStart(order.id, subMenuId, false, "recall");
      return;
    }
  };

  const handleClickSummary = (id: number) => {
    setSelectedSummary((prev) => (id === prev ? null : id));
  };

  return (
    <SocketProvider>
      <KitchenTemplates
        className="sm:grid-cols-1 md:grid-cols-2"
        header={<KitchenHeader title="KDS Cooking" goto="/expo" />}
        navigation={
          <KitchenNavigation
            orders={orders}
            selectedSummary={selectedSummary}
            onClickSummary={handleClickSummary}
          />
        }
      >
        <div className="flex">
          <CookingList
            label="START"
            orders={orders?.filter((order) => order.isShowAtStart)}
            onClickActionOrder={(type: ActionMenuType, order: Order) => {
              handleClickActionMenu(type, order, "START");
            }}
            onClickActionItem={(params: ActionMenu) => {
              handleClickActionItem(params, "START");
            }}
            selectedSummary={selectedSummary}
          />

          <CookingList
            label="COOKING"
            orders={orders?.filter(
              (order) => order.isShowAtCooking && !order.isDeleted,
            )}
            onClickActionOrder={(type: ActionMenuType, order: Order) => {
              handleClickActionMenu(type, order, "COOKING");
            }}
            onClickActionItem={(params: ActionMenu) => {
              handleClickActionItem(params, "COOKING");
            }}
            selectedSummary={selectedSummary}
            timedWarnings={{
              onTrack: settingForm?.timeWarning?.[0]?.minutes,
              warning: settingForm?.timeWarning?.[1]?.minutes,
              critical: settingForm?.timeWarning?.[2]?.minutes,
            }}
          />
        </div>
      </KitchenTemplates>

      <SettingDialog />
    </SocketProvider>
  );
};

export default Authn(HomeScreen);
