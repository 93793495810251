import { useState } from "react";

type SelectedItem = {
  label: string;
  value: number;
};

type SelectedState = {
  [label: string]: number[];
};

type UseSelectedReturnType = {
  selected: SelectedState;
  setSelected: (item: SelectedItem) => void;
};

function useSelected(): UseSelectedReturnType {
  const [selected, setSelected] = useState<SelectedState>({});

  const toggleItem = (item: SelectedItem) => {
    setSelected((prevSelected) => {
      const { label, value } = item;
      const labelValues = prevSelected[label] || [];
      const newLabelValues = labelValues.includes(value)
        ? labelValues.filter((selectedValue) => selectedValue !== value)
        : [...labelValues, value];
      return { ...prevSelected, [label]: newLabelValues };
    });
  };

  return { selected, setSelected: toggleItem };
}

export default useSelected;
