import { NumberButton } from "components/atoms/button/NumberButton";
import { LoaderIcon } from "components/icons";
import { Input } from "components/ui/input";
import { useLoginState } from "internal/login/state";
import { cn } from "lib/utils";
import { Delete } from "lucide-react";

export const LoginTemplates = () => {
  const { codeValue, setCodeValue, isPending, loginType } = useLoginState();
  return (
    <div className="text-center">
      <h2 className="text-xl md:text-4xl font-semibold mb-6">
        Enter {loginType === "pin" ? "PIN" : "Employee ID"}
      </h2>
      <p className="leading-6 text-slate-400 tracking-wider text-sm md:text-base">
        Please enter your{" "}
        <span className="text-slate-900">
          {loginType === "pin" ? "PIN" : "Employee ID"}{" "}
        </span>
        to continue
      </p>
      <Input
        className={cn(
          "w-full p-3 rounded-lg !outline-secondary tracking-wider mt-6",
          {
            "tracking-[10px] text-xl pl-4": codeValue.length !== 0,
          },
        )}
        type="password"
        placeholder="Enter your Employee ID"
        value={codeValue}
        onChange={(e) => setCodeValue(e.target.value)}
        disabled={isPending}
        suffix={
          isPending ? (
            <LoaderIcon className="animate-spin text-teal-500 font-bold w-5 h-5" />
          ) : undefined
        }
      />
      <div className="grid grid-cols-3 mt-6 rounded-lg bg-white">
        {Array.from({ length: 9 }, (_, i) => i + 1).map((item) => (
          <NumberButton
            key={item}
            onClick={() =>
              codeValue.length < 6 && setCodeValue(codeValue + item)
            }
          >
            {item}
          </NumberButton>
        ))}
        <NumberButton disabled />
        <NumberButton
          onClick={() => codeValue.length < 6 && setCodeValue(codeValue + 0)}
        >
          0
        </NumberButton>
        <NumberButton onClick={() => setCodeValue(codeValue.slice(0, -1))}>
          <Delete className="w-5 md:w-7" />
        </NumberButton>
      </div>
    </div>
  );
};
