import {
  GetPanicResponseType,
  GetValidationResponseType,
} from "internal/base/type";
import { toast } from "sonner";

const ErrorHandler = (err: any) => {
  let title;
  let description;
  const validation: GetValidationResponseType = err?.response?.data;
  if (validation?.errors !== undefined && validation?.errors?.length > 0) {
    title = "Validation Error";
    description = validation?.errors?.join("\n");

    toast.error(title, { description });
    return;
  }

  const panic: GetPanicResponseType = err?.response?.data;
  if (panic?.message !== undefined) {
    title = "Internal Server Error";
    description = panic.message;

    toast.error(title, { description });
    return;
  }

  const generalResponseError = err?.response?.data;
  if (generalResponseError?.error !== undefined) {
    title =
      generalResponseError?.error?.type &&
      generalResponseError?.error?.type.replace("_", " ");
    description =
      generalResponseError?.error.description &&
      generalResponseError?.error.description;

    toast.error(title, { description });
    return;
  }

  const generalError = err;
  if (generalError?.message !== undefined) {
    title = "Internal Client Error";
    description = generalError.message;

    toast.error(title, { description });
    return;
  }
};

export default ErrorHandler;
