import { ChecklistIcon } from "components/icons/Checklist";
import { areAllSubMenusBumped, areAllSubMenusDeleted, cn } from "lib/utils";
import { useMemo } from "react";

interface Props {
  menu: Menu;
  selectedSummary?: number | null;
  label: string;
}

export default function OrderItem(props: Props) {
  const { selectedSummary, label } = props;
  const { name, quantity, subMenus, id } = props.menu;

  const isCookingLabel = label === "COOKING";
  const isOrderListLabel = label === "ORDER LIST";

  const bumpedClassName = isOrderListLabel ? "text-success" : "line-through";

  const bumpedMenuStartClassName = useMemo(() => {
    const isAllClear = !isCookingLabel
      ? areAllSubMenusBumped(props.menu)
      : areAllSubMenusDeleted(props.menu);

    return isAllClear ? bumpedClassName : "";
  }, [props.menu, isCookingLabel]);

  const selectedClassName =
    selectedSummary === id ? "text-destructive font-bold" : "";

  return (
    <div
      className={cn("px-3 py-2 font-semibold border-b-2", selectedClassName)}
    >
      <p className={cn(bumpedMenuStartClassName)}>
        {quantity > 1 ? `(${quantity}) ` : ""}
        {name}
      </p>
      {subMenus
        .filter((subMenu: subMenus) =>
          isCookingLabel ? subMenu.isBumped : subMenu,
        )
        ?.map((subMenu: subMenus) => {
          const isClear =
            !isCookingLabel || isOrderListLabel
              ? subMenu.isBumped
              : subMenu.isDeleted;

          const bumpedStartClassName = isClear ? bumpedClassName : "";

          return (
            <div key={subMenu.id} className={cn("pl-4", selectedClassName)}>
              <div className="flex justify-between items-center">
                <p className={cn(bumpedStartClassName)}>{subMenu.label}</p>
                {isOrderListLabel && subMenu.isBumped && <ChecklistIcon />}
              </div>

              {subMenu.extras && (
                <div className={cn("pl-5", bumpedStartClassName)}>
                  {subMenu.extras?.map((extra: Extas) => (
                    <p
                      key={extra.id}
                      className={cn(
                        "text-gray-600",
                        selectedClassName,
                        bumpedStartClassName,
                      )}
                    >
                      {extra.label}
                    </p>
                  ))}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
