import HomeScreen from "screens/home";
import LoginScreen from "screens/login";
import ExpoScreen from "screens/expo";
import { createBrowserRouter } from "react-router-dom";
import OnboardingScreen from "screens/onboarding";
import { InstallationProvider } from "internal/installation/state/context";
import { SettingProvider } from "hooks/useSetting";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <SettingProvider>
        <HomeScreen />
      </SettingProvider>
    ),
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/expo",
    element: (
      <SettingProvider>
        <ExpoScreen />
      </SettingProvider>
    ),
  },
  {
    path: "/onboarding",
    element: (
      <InstallationProvider>
        <OnboardingScreen />
      </InstallationProvider>
    ),
  },
]);
