import { Button } from "components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { cn } from "lib/utils";
import { X } from "lucide-react";
import { ReactNode } from "react";

export interface DialogBaseProps {
  open: boolean;
  children: ReactNode;
  title?: string;
  onClose?: () => void;
  onSave?: () => void;
  className?: string;
  disabled?: boolean;
}

const DIALOG_PADDING = "p-4";

export default function DialogBase(props: DialogBaseProps) {
  const { open, title, children, onClose, onSave, className, disabled } = props;

  return (
    <Dialog open={open}>
      <DialogContent
        onEscapeKeyDown={onClose}
        className={cn("p-0 max-w-3xl w-full", "[&>button]:hidden", className)}
      >
        {title && (
          <DialogHeader
            className={cn(
              "border-b flex flex-row justify-between items-center",
              DIALOG_PADDING,
            )}
          >
            <DialogTitle className="text-2xl font-semibold">
              {title}
            </DialogTitle>

            <Button
              onClick={onClose}
              variant="ghost"
              size="icon"
              className="!m-0"
            >
              <X color="#1C324A" className="m-0" />
            </Button>
          </DialogHeader>
        )}

        <div className={cn("flex flex-col gap-8", DIALOG_PADDING)}>
          {children}
        </div>

        {(onClose || onSave) && (
          <DialogFooter className={cn("border-t", DIALOG_PADDING)}>
            <DialogClose className="gap-4 flex">
              {onClose && (
                <Button
                  onClick={onClose}
                  variant="outline"
                  disabled={disabled}
                  className="border-secondary text-secondary hover:text-[#5cc4f0]"
                >
                  Cancel
                </Button>
              )}
              {onSave && (
                <Button
                  onClick={onSave}
                  disabled={disabled}
                  variant="secondary"
                >
                  Save
                </Button>
              )}
            </DialogClose>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
}
