import { AxiosResponse } from "axios";
import { HTTPClientNonAuth } from "internal/base/http";
import { SettingFormType } from "internal/setting/type";

export const FindSetting = (): Promise<AxiosResponse> => {
  return HTTPClientNonAuth().get("/kitchen_display/");
};

export const UpdateSetting = (
  settingForm: SettingFormType,
): Promise<AxiosResponse> => {
  const body = {
    ip_address: settingForm?.ipAddress,
    item_price: settingForm?.itemPrice,
    time_warning: settingForm?.timeWarning,
    kitchen_printer: {
      status: settingForm?.kitchenPrinterStatus,
      ip_address: settingForm?.kitchenPrinterIpAddress,
    },
    checker_printer: {
      status: settingForm?.checkerPrinterStatus,
      ip_address: settingForm?.checkerPrinterIpAddress,
    },
  };

  return HTTPClientNonAuth().post("/kitchen_display/", body);
};
