import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function scrollTo(id: string, offset: number) {
  const element = document.getElementById(id);

  if (element) {
    const selected = element?.children?.[1];
    const currentOffset =
      -element?.children?.[1].children?.[0]?.getBoundingClientRect()?.top;

    const headerOffset = currentOffset + offset;
    const elementPosition = selected.getBoundingClientRect().top;
    const offsetPosition = elementPosition + headerOffset;

    selected.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}

export const getStatusTimedWarnings = (
  elapsedTime: number,
  timedWarnings?: TimedWarnings,
) => {
  if (!timedWarnings) {
    return "DEFAULT";
  }

  const warning = timedWarnings?.warning || 3;
  const critical = timedWarnings?.critical || 5;

  if (elapsedTime < 60 * warning) {
    return "GOOD";
  }

  if (elapsedTime < 60 * critical) {
    return "NOT_GOOD";
  }

  if (elapsedTime >= 60 * critical) {
    return "CRITICAL";
  }

  return "DEFAULT";
};

export const areAllSubMenusBumped = (menu: Menu): boolean => {
  return menu.subMenus.every((subMenu) => subMenu?.isBumped === true);
};

export const areAllSubMenusDeleted = (menu: Menu): boolean => {
  return menu.subMenus.every((subMenu) => subMenu?.isDeleted === true);
};

export const isAnySubMenuBumped = (menu: Menu): boolean => {
  return menu.subMenus.some((subMenu) => subMenu.isBumped === true);
};

export const isAnyBumpedInOrder = (order: Order): boolean => {
  return order.menus.some((menu) => isAnySubMenuBumped(menu));
};

export const areAllBumpedInOrder = (order: Order): boolean => {
  return order.menus.every((menu) => areAllSubMenusBumped(menu));
};

export const areAllDeletedInOrder = (order: Order): boolean => {
  return order?.menus?.every((menu) => areAllSubMenusDeleted(menu));
};
