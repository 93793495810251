import {
  LabelTimedWarnings as Label,
  SettingItem,
  SettingItemList,
} from "components/atoms/setting-item";
import { DialogBase } from "components/molecules/dialog";
import { Input } from "components/ui/input";
import { Switch } from "components/ui/switch";
import { useSetting } from "hooks/useSetting";

export default function SettingDialog() {
  const {
    open,
    setOpen,
    handleSave,
    settingForm,
    isLoading,
    form,
    updateForm,
    updateFormTimeWarning,
  } = useSetting();

  return (
    <DialogBase
      title="KDS Configuration"
      open={open}
      onClose={() => {
        setOpen(false);
        updateForm(settingForm);
      }}
      onSave={() => handleSave(form)}
      disabled={isLoading}
    >
      <SettingItemList label="General">
        <SettingItem label="IP Address">{form.ipAddress || "-"}</SettingItem>
        <SettingItem label="Item price">
          <div className="flex items-center gap-3">
            <Switch
              checked={form?.itemPrice}
              onCheckedChange={(e) => updateForm({ itemPrice: e })}
              className="data-[state=checked]:bg-secondary"
            />
            <span>{form?.itemPrice ? "shown" : "hidden"}</span>
          </div>
        </SettingItem>
      </SettingItemList>
      <SettingItemList label="Timed Warning">
        <SettingItem label={<Label label="1. On track" color="bg-success" />}>
          <Input
            type="number"
            value={form?.timeWarning?.[0]?.minutes}
            onChange={(e) => {
              updateFormTimeWarning(0, { minutes: Number(e.target.value) });
            }}
            suffix={<div>Minutes</div>}
          />
        </SettingItem>
        <SettingItem label={<Label label="2. Warning" color="bg-warning" />}>
          <Input
            type="number"
            value={form?.timeWarning?.[1]?.minutes}
            onChange={(e) => {
              updateFormTimeWarning(1, { minutes: Number(e.target.value) });
            }}
            suffix={<div>Minutes</div>}
          />
        </SettingItem>
        <SettingItem label={<Label label="3. Critical" color="bg-danger" />}>
          <Input
            type="number"
            value={form?.timeWarning?.[2]?.minutes}
            onChange={(e) => {
              updateFormTimeWarning(2, { minutes: Number(e.target.value) });
            }}
            suffix={<div>Minutes</div>}
          />
        </SettingItem>
      </SettingItemList>
      <SettingItemList label="Printer" direction="col">
        <div className="flex">
          <SettingItem label="Kitchen printer">
            <div className="flex items-center gap-3">
              <Switch
                checked={form.kitchenPrinterStatus}
                onCheckedChange={(active: boolean) => {
                  updateForm({ kitchenPrinterStatus: active });
                }}
                className="data-[state=checked]:bg-secondary"
              />
              <span>{form.kitchenPrinterStatus ? "Active" : "Inactive"}</span>
            </div>
          </SettingItem>
          <SettingItem label="IP address" width="w-full">
            <Input
              className="w-full"
              value={form.kitchenPrinterIpAddress}
              disabled={!form.kitchenPrinterStatus}
              onChange={(e) => {
                updateForm({ kitchenPrinterIpAddress: e.target.value });
              }}
            />
          </SettingItem>
        </div>
        <div className="flex">
          <SettingItem label="Checker printer">
            <div className="flex items-center gap-3">
              <Switch
                checked={form.checkerPrinterStatus}
                onCheckedChange={(active: boolean) => {
                  updateForm({ checkerPrinterStatus: active });
                }}
                className="data-[state=checked]:bg-secondary"
              />
              <span>{form.checkerPrinterStatus ? "Active" : "Inactive"}</span>
            </div>
          </SettingItem>
          <SettingItem label="IP address" width="w-full">
            <Input
              className="w-full"
              value={form.checkerPrinterIpAddress}
              disabled={!form.checkerPrinterStatus}
              onChange={(e) => {
                updateForm({ checkerPrinterIpAddress: e.target.value });
              }}
            />
          </SettingItem>
        </div>
      </SettingItemList>
    </DialogBase>
  );
}
