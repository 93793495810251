import { AbortInstallation } from "components/organisms/installation";
import { useInstallationContext } from "internal/installation/state/context";

const OnboardingScreen = () => {
  const { renderContent } = useInstallationContext();
  return (
    <div className="flex min-h-screen ">
      <div className="md:w-1/2 w-0 bg-[#2F2D31] relative flex justify-center items-center">
        <img
          className="max-w-[328px] w-1/2 absolute top-0 left-0"
          src="/background.png"
          alt="background"
        />
        <img
          className="max-w-[159px] w-1/2"
          src="/onboarding-logo.png"
          alt="onboarding-logo"
        />
      </div>
      <div className="w-full md:w-1/2 flex justify-center items-center bg-slate-100">
        <AbortInstallation />
        {renderContent()}
      </div>
    </div>
  );
};

export default OnboardingScreen;
