import { Button } from "components/ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";

interface Props {
  direction: "up" | "down";
  onClick: () => void;
}

const icon = {
  up: ChevronUp,
  down: ChevronDown,
};

export default function ArrowButton(props: Props) {
  const { direction, onClick } = props;

  const IconButton = icon[direction];

  return (
    <Button onClick={onClick} className="flex-auto hover:bg-gray-300 bg-white">
      <IconButton className="text-gray-600" />
    </Button>
  );
}
