import { DialogBase } from "components/molecules/dialog";
import { Button } from "components/ui/button";
import { useInstallationContext } from "internal/installation/state/context";
import { AlertCircle, X } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const AbortInstallation = () => {
  const {
    onCloseAbort,
    openAbort: type,
    onOpenAbort,
  } = useInstallationContext();
  const navigate = useNavigate();
  return (
    <>
      <Button
        onClick={() => onOpenAbort("exit")}
        variant="outline"
        className="absolute w-[60px] h-[60px] top-0 right-0 bg-slate-100 hover:bg-slate-200 rounded-none border-none"
      >
        <X />
      </Button>

      <DialogBase className="max-w-[480px]" open={!!type}>
        <div className="text-center max-w-[320px] mx-auto w-full p-6">
          <div
            className={
              "w-12 h-12 rounded-full flex justify-center items-center mx-auto mb-6 bg-lightwarning"
            }
          >
            <AlertCircle className="text-white w-5 h-5" />
          </div>
          <h2 className="text-2xl font-semibold mb-4 tracking-wide">
            {type === "exit"
              ? "Exit installation Wizard?"
              : "Cancel installation?"}
          </h2>

          <p className="my-8 text-sm text-gray-700 tracking-wider">
            Are you sure you want to cancel this installation ?
          </p>
          <div className="gap-5 flex">
            <Button
              onClick={onCloseAbort}
              variant="outline"
              className="w-full border-secondary text-secondary hover:text-secondary"
            >
              Continue
            </Button>
            <Button
              variant="secondary"
              className="w-full capitalize"
              onClick={() => navigate("/")}
            >
              Yes, {type}
            </Button>
          </div>
        </div>
      </DialogBase>
    </>
  );
};
