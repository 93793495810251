import { HTMLAttributes } from "react";

export function LoaderIcon(props: HTMLAttributes<SVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="url(#paint0_angular_1507_23516)"
        d="M16 8A8 8 0 110 8a8 8 0 0116 0zM3.6 8a4.4 4.4 0 108.8 0 4.4 4.4 0 00-8.8 0z"
      ></path>
      <path
        fill="#2EBFCC"
        d="M9.707 14.187a1.813 1.813 0 11-3.627 0 1.813 1.813 0 013.627 0z"
      ></path>
      <defs>
        <radialGradient
          id="paint0_angular_1507_23516"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 8 -8 0 8 8)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2ABFCC"></stop>
          <stop offset="1" stopColor="#2ABFCC" stopOpacity="0.12"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}
