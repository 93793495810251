import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "components/ui/sonner";

import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { router } from "./route";
import { BlurProvider } from "hooks/UseBlur";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <BlurProvider>
        <RouterProvider router={router} />
        <Toaster richColors expand position="top-right" />
      </BlurProvider>
    </QueryClientProvider>
  </>,
);
