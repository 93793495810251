import { ArrowButton } from "components/atoms/button";

interface Props {
  onClick: (direction: string) => void;
}

export default function ButtonArrow(props: Props) {
  const { onClick } = props;

  return (
    <div className="flex gap-2 w-full">
      <ArrowButton direction="up" onClick={() => onClick("up")} />
      <ArrowButton direction="down" onClick={() => onClick("down")} />
    </div>
  );
}
