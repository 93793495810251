import { Card } from "components/ui/card";
import { cn } from "lib/utils";

interface Props {
  name: string;
  quantity: number;
  onClick: () => void;
  isSelected: boolean;
}

const OrderItemSummary = ({ name, quantity, onClick, isSelected }: Props) => {
  const selectedClassName = isSelected
    ? "border-destructive text-destructive font-bold"
    : "border-none";

  return (
    <Card
      onClick={onClick}
      className={cn(
        "bg-[#FFFFFF1A] rounded-sm text-center text-white p-2 py-4 cursor-pointer select-none px-4",
        selectedClassName,
      )}
    >
      <h5 className="text-2xl font-semibold mb-1">{quantity}</h5>
      <span>{name}</span>
    </Card>
  );
};

export default OrderItemSummary;
