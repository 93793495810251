import { cn } from "lib/utils";

interface Props {
  label: string;
  color: string;
}

export default function LabelTimedWarnings(props: Props) {
  const { label, color } = props;

  return (
    <div className="flex flex-row items-center gap-2">
      <p>{label}</p>
      <div className={cn("w-3 h-3 rounded-full", color)} />
    </div>
  );
}
