import { FindSocketBaseURL } from "internal/base/config";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import { toast } from "sonner";

type SocketStateType = {
  messageHistory: Array<SocketDataType>;
  sendJsonMessage: SendJsonMessage;
  connectionStatus: string;
};

type SocketProviderPropsType = {
  children: ReactNode;
};

type SocketDataType = {
  data: string;
};

const SocketContext = createContext<SocketStateType>({
  messageHistory: [],
  sendJsonMessage: () => undefined,
  connectionStatus: "",
});

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a Socket Provider");
  }
  return context;
};

export const SocketProvider = ({ children }: SocketProviderPropsType) => {
  const [messageHistory, setMessageHistory] = useState<SocketDataType[]>([]);

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useWebSocket<SocketDataType>(FindSocketBaseURL(), {
      shouldReconnect: () => true,
      reconnectInterval: 3000,
      onError: () =>
        toast.error("Socket Connection Failed", {
          description: "Try to check connection",
        }),
      onOpen: () => toast.success("Socket Connection Success"),
      retryOnError: true,
    });

  useEffect(() => {
    if (lastJsonMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastJsonMessage));
    }
  }, [lastJsonMessage, setMessageHistory]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return (
    <SocketContext.Provider
      value={{ messageHistory, sendJsonMessage, connectionStatus }}
    >
      {children}
    </SocketContext.Provider>
  );
};
