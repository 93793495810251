const DUMY_MENU_1: Menu[] = [
  {
    id: 1,
    name: "Combo 4 Fire Wings",
    quantity: 2,
    subMenus: [
      {
        id: 1,
        label: "Fire Wings 4pcs",
        isBumped: false,
        extras: [
          {
            id: 1,
            label: "(4) Wings",
          },
          {
            id: 2,
            label: "Lev 1",
          },
        ],
      },
      {
        id: 2,
        label: "Rice",
        isBumped: false,
      },
      {
        id: 3,
        label: "Fire French fries",
        isBumped: false,
        extras: [
          {
            id: 3,
            label: "Lev 1",
          },
        ],
      },
    ],
  },
];

const DUMY_MENU_2: Menu[] = [
  {
    id: 4,
    name: "Combo 1 Fire Chicken",
    quantity: 3,
    subMenus: [
      {
        id: 4,
        label: "Fire Chicken 1pc",
        isBumped: false,
        extras: [
          {
            id: 4,
            label: "OR-Fire chicken",
          },
          {
            id: 5,
            label: "Lev 3",
          },
        ],
      },
      {
        id: 5,
        label: "Rice",
        isBumped: false,
      },
    ],
  },
  {
    id: 5,
    name: "BBQ Cheesy Wedges",
    quantity: 2,
    subMenus: [
      {
        id: 6,
        label: "Lev 1",
        isBumped: false,
      },
    ],
  },
];

const DUMY_MENU_3: Menu[] = [
  {
    id: 5,
    name: "BBQ Cheesy Wedges",
    quantity: 2,
    subMenus: [
      {
        id: 7,
        label: "Lev 1",
        isBumped: false,
      },
    ],
  },
  {
    id: 6,
    name: "Fire Flying Chicken",
    quantity: 1,
    subMenus: [
      {
        id: 8,
        label: "Fly Non-Spicy",
        isBumped: false,
      },
    ],
  },
];

const DUMY_MENU_4: Menu[] = [
  {
    id: 7,
    name: "Combo 2 Amo Baby Fire Chicken",
    quantity: 1,
    subMenus: [
      {
        id: 9,
        label: "Lev 3",
        isBumped: false,
        extras: [
          {
            id: 4,
            label: "(4) Wings",
          },
          {
            id: 5,
            label: "Lev 1",
          },
        ],
      },
      {
        id: 10,
        label: "Rice",
        isBumped: false,
      },
      {
        id: 11,
        label: "Fire French fries",
        isBumped: false,
        extras: [
          {
            id: 1,
            label: "Lev 1",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "BBQ Cheesy Wedges",
    quantity: 2,
    subMenus: [
      {
        id: 6,
        label: "Lev 1",
        isBumped: false,
      },
    ],
  },
  {
    id: 6,
    name: "Fire Flying Chicken",
    quantity: 1,
    subMenus: [
      {
        id: 8,
        label: "Fly Non-Spicy",
        isBumped: false,
      },
    ],
  },
];

export const ORDER_READY: Order[] = [
  {
    id: 4,
    menus: DUMY_MENU_1,
    bumpedAt: null,
    isShowAtStart: true,
  },
  {
    id: 5,
    menus: DUMY_MENU_3,
    bumpedAt: null,
    isShowAtStart: true,
  },
  {
    id: 6,
    menus: DUMY_MENU_4,
    bumpedAt: null,
    isShowAtStart: true,
  },
  {
    id: 7,
    menus: DUMY_MENU_4,
    bumpedAt: null,
    isShowAtStart: true,
  },
];

export const ORDER_SERVED: Order[] = [
  {
    id: 1,
    menus: DUMY_MENU_1,
    bumpedAt: null,
    isShowAtStart: true,
  },
  {
    id: 2,
    menus: DUMY_MENU_2,
    bumpedAt: null,
    isShowAtStart: true,
  },
  {
    id: 3,
    menus: DUMY_MENU_2,
    bumpedAt: null,
    isShowAtStart: true,
  },
];
