import ErrorHandler from "internal/base/middleware/error-handler";
import { defaultSettingForm } from "internal/setting/const";
import { FindSetting, UpdateSetting } from "internal/setting/http";
import {
  SettingFormType,
  SettingStateType,
  TimeWarningItem,
} from "internal/setting/type";
import { useEffect, useReducer, useState } from "react";
import { toast } from "sonner";

export const useSettingState = (): SettingStateType => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [settingForm, setSettingForm] = useReducer(
    (prev: SettingFormType, next: any): SettingFormType => {
      return { ...prev, ...next };
    },
    defaultSettingForm,
  );

  const [form, updateForm] = useReducer(
    (prev: SettingFormType, next: Partial<SettingFormType>) => {
      return { ...prev, ...next };
    },
    settingForm,
  );

  useEffect(() => {
    FindSetting()
      .then((res) => {
        const data = res.data?.data?.[0] || {};

        setSettingForm({
          ipAddress: data?.ip_address,
          itemPrice: data?.item_price,
          timeWarning: data?.time_warning,
          kitchenPrinterStatus: data?.kitchen_printer?.status,
          kitchenPrinterIpAddress: data?.kitchen_printer?.ip_address,
          checkerPrinterStatus: data?.checker_printer?.status,
          checkerPrinterIpAddress: data?.checker_printer?.ip_address,
        });
      })
      .catch(ErrorHandler);
  }, []);

  const handleSave = (values: SettingFormType) => {
    setIsLoading(true);

    UpdateSetting(values)
      .then(() => {
        setSettingForm(values);
        toast.success("Success Save Configuration");
      })
      .catch(ErrorHandler)
      .finally(() => {
        setOpen(false);
        setIsLoading(false);
      });
  };

  const updateFormTimeWarning = (
    index: number,
    newTimeWarning: Partial<TimeWarningItem>,
  ) => {
    updateForm({
      timeWarning: form.timeWarning.map((item, i) => {
        if (i === index) {
          return { ...item, ...newTimeWarning };
        }
        return item;
      }),
    });
  };

  return {
    open,
    setOpen,
    setSettingForm,
    handleSave,
    settingForm,
    isLoading,
    form,
    updateForm,
    updateFormTimeWarning,
  };
};
