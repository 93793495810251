"use-strict";

export const GetInstallment = (): string => {
  return (
    localStorage.getItem(process.env.REACT_APP_INSTALLMENT || "installment") ||
    ""
  );
};

export const SetInstallment = (token: string): void => {
  localStorage.setItem(
    process.env.REACT_APP_INSTALLMENT || "installment",
    token,
  );
};

export const ClearInstallment = (): void => {
  localStorage.removeItem(process.env.REACT_APP_INSTALLMENT || "installment");
};
