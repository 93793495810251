import { OrderItemDetail } from "components/atoms/order-item";
import { ButtonArrow } from "components/molecules/button-action";
import { CookingCard } from "components/molecules/card";
import { ScrollArea } from "components/ui/scroll-area";
import { useBlur } from "hooks/UseBlur";
import {
  areAllDeletedInOrder,
  areAllSubMenusDeleted,
  cn,
  isAnySubMenuBumped,
  scrollTo,
} from "lib/utils";
import Masonry from "react-masonry-css";
import "./style.css";
import { useMemo } from "react";

interface Props {
  label: string;
  orders?: Order[];
  defaultCols?:
    | number
    | { default: number; [key: number]: number }
    | { [key: number]: number };
  onClickActionOrder: (type: ActionMenuType, order: Order) => void;
  onClickActionItem: (params: ActionMenu) => void;
  timedWarnings?: TimedWarnings;
  selectedSummary?: number | null;
}

const getMasonryLayout = (orders: any[]) => {
  return [...orders, ...(orders.length === 1 ? [{}] : [])];
};

export default function CookingList(props: Props) {
  const {
    label,
    defaultCols,
    orders,
    onClickActionOrder,
    onClickActionItem,
    timedWarnings,
    selectedSummary,
  } = props;
  const { on, off, blurId, setBlur } = useBlur();

  const handleClickArrow = (direction: string) => {
    const offset = direction === "down" ? 500 : -500;
    scrollTo(`wrap-scroll-${label}`, offset);
  };

  const handleOpenChange = (isOpen: boolean, id: number) => {
    setBlur(isOpen ? id : null);
  };

  const breakpointCols = defaultCols || {
    default: 2,
    1024: 1,
  };

  const ordersFiltered = useMemo(() => {
    return orders?.filter((order) => {
      if (label !== "START") {
        return !areAllDeletedInOrder(order);
      }

      return order;
    });
  }, [orders]);

  return (
    <div className="border-l border-gray-500 p-4 h-full flex-1">
      <div
        className={cn(
          on,
          "flex items-center justify-center mb-3 flex-col lg:flex-row gap-2",
        )}
      >
        <h4 className="w-full lg:w-2/3 text-3xl font-semibold tracking-widest">
          {label}
        </h4>
        <div className="w-full lg:w-1/3">
          <ButtonArrow onClick={handleClickArrow} />
        </div>
      </div>
      <ScrollArea
        id={`wrap-scroll-${label}`}
        className="h-screen pb-40 scroll-wrapper"
      >
        <Masonry
          breakpointCols={breakpointCols}
          className="my-masonry-grid gap-3"
          columnClassName="my-masonry-grid_column flex-1"
        >
          {getMasonryLayout(ordersFiltered as any[]).map(
            (order: Order, idx: Number) => {
              if (order.id) {
                const filteredMenus = order?.menus.filter((menu: Menu) => {
                  if (label === "COOKING") {
                    return (
                      isAnySubMenuBumped(menu) && !areAllSubMenusDeleted(menu)
                    );
                  }

                  return menu;
                });

                return (
                  <div key={order.id} className="item">
                    <CookingCard
                      {...order}
                      orderId={order.id}
                      blurClassName={blurId === order.id ? off : on}
                      labelSection={label}
                      timedWarnings={timedWarnings}
                      bumpedAt={order.bumpedAt}
                      onOpenChange={(isOpen) => {
                        handleOpenChange(isOpen, order.id);
                      }}
                      onClickActionOrder={(type: ActionMenuType) => {
                        onClickActionOrder(type, order);
                      }}
                      onClickActionItem={(
                        params: Omit<ActionMenu, "order">,
                      ) => {
                        const { isBumped, subMenuId, type } = params;
                        onClickActionItem({ type, order, subMenuId, isBumped });
                      }}
                    >
                      {filteredMenus?.map((menu: Menu) => (
                        <OrderItemDetail
                          key={menu.id}
                          menu={menu}
                          selectedSummary={selectedSummary}
                          label={label}
                        />
                      ))}
                    </CookingCard>
                  </div>
                );
              }

              return <div key={`${idx}`} />;
            },
          )}
        </Masonry>
      </ScrollArea>
    </div>
  );
}
