import { OrderItemSummary } from "components/atoms/order-item";
import { ButtonArrow } from "components/molecules/button-action";
import { ScrollArea } from "components/ui/scroll-area";
import { useBlur } from "hooks/UseBlur";
import { cn, scrollTo } from "lib/utils";
import { useMemo } from "react";

interface Props {
  orders: Partial<Order>[];
  selectedSummary: number | null;
  onClickSummary: (id: number) => void;
}

interface Summary {
  id: number;
  quantity: number;
  name: string;
}

interface MenuItem {
  id: number;
  name: string;
  quantity: number;
}

function removeDuplicatesAndSumQuantities(items: MenuItem[] | any): MenuItem[] {
  const map = new Map<number, MenuItem>();

  for (const item of items) {
    if (map.has(item.id)) {
      const existingItem = map.get(item.id)!;
      existingItem.quantity += item.quantity;
    } else {
      map.set(item.id, {
        id: item.id,
        name: item.name,
        quantity: item.quantity,
      });
    }
  }

  return Array.from(map.values());
}

export default function KitchenNavigation(props: Props) {
  const { orders, onClickSummary, selectedSummary } = props;
  const { on } = useBlur();

  const generatedSummary = useMemo(() => {
    const flaten = orders
      .filter((order) => order.id)
      .map((order: Partial<Order>) => {
        return order.menus?.map((menu: Menu) => ({
          id: menu.id,
          name: menu.name,
          quantity: menu.quantity,
        }));
      })
      .flat();

    return removeDuplicatesAndSumQuantities(flaten) || [];
  }, [orders]);

  const handleClickArrow = (direction: string) => {
    const offset = direction === "down" ? 633 : -633;
    scrollTo("scroll-area-summary-order", offset);
  };

  return (
    <aside className={cn(on, "bg-primary flex flex-col h-full")}>
      <ScrollArea id="scroll-area-summary-order" className="p-3 h-screen">
        <h2 className="text-white text-xl text-center font-semibold">
          Summary Order
        </h2>
        <div className="flex flex-col gap-2 mt-4">
          {(generatedSummary as Summary[]).map(
            (summary: Summary, idx: number) => {
              const { id } = summary;

              return (
                <OrderItemSummary
                  key={summary.id}
                  quantity={summary.quantity}
                  name={summary.name}
                  onClick={() => onClickSummary(summary.id)}
                  isSelected={selectedSummary === id}
                />
              );
            },
          )}
        </div>
      </ScrollArea>
      <div className="flex justify-between px-3 pb-2">
        <ButtonArrow onClick={handleClickArrow} />
      </div>
    </aside>
  );
}
