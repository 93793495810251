import { ChecklistIcon } from "components/icons/Checklist";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "components/ui/dropdown-menu";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  onOpenChange: (isOpen: boolean) => void;
  listMenu: ListMenu[];
}

type ListMenu = {
  label: string;
  onClick?: (label: string) => void;
  menus?: MenuItem[];
};

type MenuItem = {
  id: number;
  label: string;
  quantity: number;
  subMenus?: SubMenu[];
};

type SubMenu = {
  id: number;
  label: string;
  onClick: (id?: number) => void;
  checked: boolean;
};

export default function MenuBase(props: Props) {
  const { children, onOpenChange, listMenu } = props;

  return (
    <DropdownMenu onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>

      <DropdownMenuContent
        align="start"
        alignOffset={-10}
        side="right"
        sideOffset={12}
        className="w-56 p-0"
      >
        <DropdownMenuGroup>
          {listMenu?.map((list: ListMenu) => {
            if (!list?.menus) {
              return (
                <DropdownMenuItem
                  key={`${list.label}`}
                  className="p-4 font-semibold"
                  onClick={() => list?.onClick && list?.onClick(list.label)}
                >
                  <span>{list.label}</span>
                </DropdownMenuItem>
              );
            }

            return (
              <DropdownMenuSub key={`${list.label}`}>
                <DropdownMenuSubTrigger className="p-4 font-semibold">
                  <span>{list.label}</span>
                </DropdownMenuSubTrigger>

                <DropdownMenuPortal>
                  <DropdownMenuSubContent
                    className="p-0 min-w-52 max-w-64"
                    sideOffset={6}
                  >
                    {list?.menus?.map((menu: MenuItem) => (
                      <div key={menu.id}>
                        <div className="px-4 py-2 font-semibold text-sm text-gray-400 select-none border-t border-b border-[#E3E8F5]">
                          <p>
                            {menu.quantity > 1 && `(${menu.quantity})`}{" "}
                            {menu.label}
                          </p>
                        </div>
                        <DropdownMenuSeparator className="m-0" />
                        {menu?.subMenus?.map((subMenu: SubMenu) => (
                          <div
                            key={subMenu.id}
                            onClick={() => subMenu?.onClick(subMenu.id)}
                            className="p-4 font-semibold cursor-pointer hover:bg-accent flex gap-1 items-center justify-between"
                          >
                            <span>{subMenu?.label}</span>
                            {subMenu?.checked && (
                              <ChecklistIcon className="min-w-4" />
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            );
          })}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
