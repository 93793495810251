import { ChecklistIcon } from "components/icons/Checklist";
import { useOrderState } from "components/molecules/card/useOrderState";
import { MenuBase } from "components/molecules/menu";
import { Card, CardContent, CardHeader } from "components/ui/card";
import useSelected from "hooks/useSelected";
import { getColorOrder } from "lib/helper";
import { cn, getStatusTimedWarnings, isAnySubMenuBumped } from "lib/utils";
import { MoreHorizontal } from "lucide-react";
import { ReactNode, useMemo } from "react";

interface Props {
  children: ReactNode;
  onOpenChange: (isOpen: boolean) => void;
  orderId: number;
  blurClassName: string;
  type?: string;
  status?: "GOOD" | "NOT_GOOD" | "CRITICAL";
  labelSection: string;
  bumpedAt?: number | null;
  timedWarnings?: TimedWarnings;
  menus?: Menu[];
  onClickActionOrder: (type: ActionMenuType) => void;
  onClickActionItem: (params: Omit<ActionMenu, "order">) => void;
  isShowAtStart?: boolean;
}

export default function CookingCard(props: Props) {
  const {
    children,
    onOpenChange,
    blurClassName,
    orderId,
    type,
    labelSection,
    bumpedAt,
    timedWarnings,
    menus,
    onClickActionOrder,
    onClickActionItem,
    isShowAtStart,
  } = props;

  const isCookingSection = labelSection === "COOKING";
  const isOrderListSection = labelSection === "ORDER LIST";

  const orderListBg = isShowAtStart ? "bg-secondary" : "bg-lightsuccess";
  const bgContent = isOrderListSection ? orderListBg : "";

  const { selected, setSelected } = useSelected();
  const { countUpValue, elapsedTime } = useOrderState(
    bumpedAt,
    !isCookingSection,
  );
  const statusElapsed = getStatusTimedWarnings(elapsedTime, timedWarnings);
  const colorOrder = getColorOrder(statusElapsed || "DEFAULT");
  const { color, lightColor, colorForeground, lightColorForeground } =
    colorOrder;

  const handleClickActionItem = (params: Omit<ActionMenu, "order">) => {
    const { type, subMenuId, isBumped } = params;
    setSelected({ label: type, value: subMenuId });
    onClickActionItem({ type, subMenuId, isBumped });
  };

  const getListSubMenu = (type: ActionMenuType) => {
    const isBumpAction = type === "bump";

    const filteredMenus = menus?.filter((menu) => {
      if (isCookingSection) {
        return isAnySubMenuBumped(menu);
      }

      return menu;
    });

    return filteredMenus?.map((menu) => {
      const filteredSubMenus = menu?.subMenus?.filter((subMenu) => {
        if (isCookingSection) {
          return subMenu.isBumped;
        }

        return subMenu;
      });

      return {
        id: menu.id,
        label: menu.name,
        quantity: menu.quantity,
        subMenus: filteredSubMenus?.map((subMenu) => {
          const isBumped = selected?.bump?.includes(subMenu.id);

          const isChecked =
            isBumpAction &&
            ((!isCookingSection || isOrderListSection
              ? subMenu?.isBumped
              : subMenu?.isDeleted) ||
              false);

          return {
            id: subMenu.id,
            label: subMenu.label,
            onClick: () => {
              handleClickActionItem({
                type: type,
                subMenuId: subMenu.id,
                isBumped: !isBumped,
              });
            },
            checked: isChecked,
          };
        }),
      };
    });
  };

  const actionMenus = useMemo(
    () => [
      {
        label: "Bump Order",
        onClick: () => onClickActionOrder("bump"),
      },
      {
        label: "Bump Item",
        menus: getListSubMenu("bump"),
      },
      ...(labelSection !== "START"
        ? [
            {
              label: "Recall Order",
              onClick: () => onClickActionOrder("recall"),
            },
            {
              label: "Recall Item",
              menus: getListSubMenu("recall"),
            },
            {
              label: "Print Order",
              onClick: () => onClickActionOrder("print"),
            },
            {
              label: "Print Item",
              menus: getListSubMenu("print"),
            },
          ]
        : []),
    ],
    [labelSection, getListSubMenu, onClickActionOrder],
  );

  return (
    <Card
      className={cn(
        color,
        blurClassName,
        "border-lg overflow-hidden border-none h-fit mb-3",
      )}
    >
      <CardHeader
        className={cn(color, "flex flex-row justify-between items-center p-2")}
      >
        <div
          className={cn(
            lightColor,
            lightColorForeground,
            "rounded-lg w-7 h-7 text-center font-semibold",
          )}
        >
          {orderId}
        </div>
        <span
          className={cn(colorForeground, lightColorForeground, "font-semibold")}
          style={{ margin: 0 }}
        >
          #12312
        </span>
        <MenuBase listMenu={actionMenus} onOpenChange={onOpenChange}>
          <MoreHorizontal
            className={cn(colorForeground, "cursor-pointer")}
            style={{ margin: 0 }}
          />
        </MenuBase>
      </CardHeader>

      {isOrderListSection && (
        <div
          className={cn(
            "rounded-tl-lg p-1 flex flex-row items-center justify-center gap-2",
            orderListBg,
          )}
        >
          <h4 className="text-white font-semibold text-md text-center">
            {isShowAtStart ? "Ready" : "Served"}
          </h4>
          {!isShowAtStart && <ChecklistIcon />}
        </div>
      )}

      <CardContent className={cn("p-0", color, bgContent)}>
        <div
          className={cn(
            lightColor,
            lightColorForeground,
            isOrderListSection ? "rounded-tl-lg" : "rounded-t-lg",
            "flex justify-between items-center px-3 py-2",
          )}
        >
          <h2 className={cn(lightColorForeground, "text-xl font-bold")}>
            {type || "Dine in"}
          </h2>
          <p className="text-md font-semibold">{countUpValue || "0:00"}</p>
        </div>

        <div className="bg-white">{children}</div>
      </CardContent>
    </Card>
  );
}
