import { Button } from "components/ui/button";
import { useInstallationContext } from "internal/installation/state/context";
import { Check } from "lucide-react";

export const InstallationResultFailed = () => {
  const { setStep, onOpenAbort, branch, company } = useInstallationContext();
  return (
    <div className="text-center max-w-[380px] w-full px-6">
      <div
        className={
          "w-12 h-12 rounded-full flex justify-center items-center mx-auto mb-6 bg-lightdanger"
        }
      >
        <Check className="text-white w-9 h-9" />
      </div>
      <h2 className="text-2xl font-semibold mb-4 tracking-wide">
        Installation failed
      </h2>

      <p className="my-8 text-sm text-gray-700 tracking-wider">
        The installation on company <strong>{company}</strong> branch{" "}
        <strong>{branch}</strong> has been failed.
      </p>
      <div className="grid gap-5">
        <Button
          variant="secondary"
          className="w-full"
          onClick={() => setStep("form")}
        >
          Try Again
        </Button>
        <Button
          variant="outline"
          className="w-full border-danger text-lightdanger hover:text-danger"
          onClick={() => onOpenAbort("cancel")}
        >
          Abort
        </Button>
      </div>
    </div>
  );
};
