import { AxiosResponse } from "axios";
import { HTTPClientNonAuth } from "internal/base/http";

export const LoginEmployeeId = (code: string): Promise<AxiosResponse> => {
  return HTTPClientNonAuth().post("/auth/login", { code });
};

export const LoginEmployeePIN = (code: string): Promise<AxiosResponse> => {
  return HTTPClientNonAuth().post("/auth/pin", { code });
};
