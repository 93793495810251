import { useState, useEffect } from "react";

interface OrderState {
  countUpValue: string;
  elapsedTime: number;
}

export function useOrderState(
  timestamp?: number | null,
  disabled?: boolean,
): OrderState {
  const [result, setResult] = useState<OrderState>({
    countUpValue: "0:00",
    elapsedTime: 0,
  });

  useEffect(() => {
    if (timestamp && !disabled) {
      const startTime = new Date(timestamp);

      const intervalId = setInterval(() => {
        const currentTime = new Date();
        const elapsedTime = Math.floor(
          (currentTime.getTime() - startTime.getTime()) / 1000,
        ); // in seconds

        const minutes = Math.floor(elapsedTime / 60);
        const seconds = elapsedTime % 60;

        const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
        setResult({
          countUpValue: formattedTime,
          elapsedTime: elapsedTime,
        });
      }, 1000); // Update every second

      return () => clearInterval(intervalId); // Cleanup function to stop the interval on unmount
    }
  }, [timestamp]);

  return {
    countUpValue: result.countUpValue,
    elapsedTime: result.elapsedTime,
  };
}
