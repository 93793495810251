import { defaultSettingState } from "internal/setting/const";
import { useSettingState } from "internal/setting/state";
import { SettingStateType } from "internal/setting/type";
import { ReactNode, createContext, useContext } from "react";

const SettingContext = createContext<SettingStateType>(defaultSettingState);

export const useSetting = (): SettingStateType => {
  const context = useContext(SettingContext);
  if (!context) throw Error("Error Context");
  return context;
};

export const SettingProvider = ({ children }: { children: ReactNode }) => {
  return (
    <SettingContext.Provider value={useSettingState()}>
      {children}
    </SettingContext.Provider>
  );
};
