import { Button } from "components/ui/button";
import { useInstallationContext } from "internal/installation/state/context";
import { Check } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const InstallationResultSuccess = () => {
  const { branch, company } = useInstallationContext();
  const navigate = useNavigate();
  return (
    <div className="text-center max-w-[380px] w-full px-6">
      <div
        className={
          "w-12 h-12 rounded-full flex justify-center items-center mx-auto mb-6 bg-lightsuccess"
        }
      >
        <Check className="text-white w-9 h-9" />
      </div>
      <h2 className="text-2xl font-semibold mb-4 tracking-wide">
        Installation complete
      </h2>

      <p className="my-8 text-sm text-gray-700 tracking-wider">
        The installation on company <strong>{company}</strong> branch{" "}
        <strong>{branch}</strong> has been completed.
      </p>
      <Button
        variant="secondary"
        className="w-full"
        onClick={() => navigate("/")}
      >
        Open KDS
      </Button>
    </div>
  );
};
