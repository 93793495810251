export type orderStatus = "GOOD" | "NOT_GOOD" | "CRITICAL" | "DEFAULT";

export const getColorOrder = (status: orderStatus = "DEFAULT") => {
  const color = {
    GOOD: "bg-lightsuccess",
    NOT_GOOD: "bg-lightwarning",
    CRITICAL: "bg-lightdanger",
    DEFAULT: "bg-[#E3E8F5]",
  };
  const lightColor = {
    GOOD: "bg-success",
    NOT_GOOD: "bg-warning",
    CRITICAL: "bg-danger",
    DEFAULT: "bg-white",
  };

  const colorForeground = {
    GOOD: "text-white",
    NOT_GOOD: "text-white",
    CRITICAL: "text-white",
    DEFAULT: "text-[#667A8E]",
  };
  const lightColorForeground = {
    GOOD: "text-white",
    NOT_GOOD: "text-white",
    CRITICAL: "text-white",
    DEFAULT: "text-[#000F1F]",
  };

  return {
    color: color[status],
    lightColor: lightColor[status],
    colorForeground: colorForeground[status],
    lightColorForeground: lightColorForeground[status],
  };
};