import { cn } from "lib/utils";
import { ReactNode } from "react";

interface Props {
  label: string | ReactNode;
  children: ReactNode;
  width?: string;
}

export default function SettingItem(props: Props) {
  const { label, children, width } = props;

  const widthStyle = width || "min-w-40";

  return (
    <div className={cn("flex flex-col gap-2", widthStyle)}>
      <p className="text-gray-600 text-md">{label}</p>
      <div>{children}</div>
    </div>
  );
}
