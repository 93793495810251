interface Props {
  label: string;
  value: string;
}

const HeaderItem = ({ label, value }: Props) => {
  return (
    <div className="text-center">
      <h4 className="text-xl font-semibold">{label}</h4>
      <p className="text-sm font-light">{value}</p>
    </div>
  );
};

export default HeaderItem;
