import React from "react";
import { AuthProvider, UserInfoType } from "../auth/state";

export type PropsType = {
  user?: UserInfoType;
};

const Authn =
  (
    Comp: React.ComponentClass<PropsType> | React.FunctionComponent<PropsType>
  ): ((props: any) => React.ReactElement) =>
  (props: any): React.ReactElement => {
    return (
      <AuthProvider>
        <Comp {...props} />
      </AuthProvider>
    );
  };

export default Authn;
