import { ChecklistIcon } from "components/icons/Checklist";
import { CookingList } from "components/organisms/cooking-list";
import { SettingDialog } from "components/organisms/dialog";
import { KitchenHeader } from "components/organisms/header";
import { KitchenNavigation } from "components/organisms/navigation";
import { KitchenTemplates } from "components/templates";
import { useBlur } from "hooks/UseBlur";
import Authn from "internal/base/middleware/authn";
import { toastMessageKey } from "lib/global";
import { areAllBumpedInOrder, isAnyBumpedInOrder } from "lib/utils";
import { useState } from "react";
import { ORDER_READY, ORDER_SERVED } from "screens/expo/data";
import { toast } from "sonner";

const getOrders = (orders: any) => {
  return [...orders, ...[{}, {}, {}]];
};

const ExpoScreen = () => {
  const { setBlur } = useBlur();
  const [selectedSummary, setSelectedSummary] = useState<number | null>(null);
  const [orders, setOrders] = useState([...ORDER_READY, ...ORDER_SERVED]);

  const deleteOrder = (orderData: Order) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => {
        if (order.id === orderData.id) {
          return { ...order, isDeleted: true };
        }
        return order;
      }),
    );
  };

  const bumpOrder = (orderData: Order) => {
    const isAnyBumpedPrev = isAnyBumpedInOrder(orderData);
    setOrders((prevOrders: Order[]) =>
      prevOrders.map((order: Order) => {
        if (order.id === orderData.id) {
          return {
            ...order,
            ...(!isAnyBumpedPrev && {
              bumpedAt: Date.now(),
            }),
            isShowAtStart: false,
            isShowAtCooking: true,
            menus: order.menus?.map((menu: Menu) => ({
              ...menu,
              subMenus: menu.subMenus?.map((subMenu: subMenus) => ({
                ...subMenu,
                isBumped: true,
              })),
            })),
          };
        }
        return order;
      }),
    );
  };

  const recallOrder = (orderData: Order) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => {
        if (order.id === orderData.id) {
          return {
            ...order,
            bumpedAt: null,
            isShowAtStart: true,
            isShowAtCooking: false,
            menus: order.menus?.map((menu: Menu) => ({
              ...menu,
              subMenus: menu.subMenus?.map((subMenu: subMenus) => ({
                ...subMenu,
                isBumped: false,
              })),
            })),
          };
        }
        return order;
      }),
    );
  };

  const handleClickActionMenu = (type: ActionMenuType, order: Order) => {
    toast.success(`Order has been ${toastMessageKey[type]} successfully`, {
      position: "top-center",
      icon: <ChecklistIcon />,
    });

    setBlur(null);

    if (type === "print") {
      return;
    }

    if (type === "bump") {
      if (order.bumpedAt !== null) {
        deleteOrder(order);
        return;
      }

      bumpOrder(order);
      return;
    }

    if (type === "recall") {
      recallOrder(order);
      return;
    }
  };

  const toggleBumpItemStart = (
    orderId: number,
    subMenuId: number,
    isBumped: boolean,
    type?: string,
  ) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => {
        const isAnyBumpedPrev = isAnyBumpedInOrder(order);

        if (order.id === orderId) {
          const updatedMenus = order.menus.map((menu) => {
            const updatedSubMenus = menu.subMenus.map((subMenu) => {
              if (subMenu.id === subMenuId) {
                return { ...subMenu, isBumped };
              }
              return subMenu;
            });
            return { ...menu, subMenus: updatedSubMenus };
          });

          const isAnyBumped = isAnyBumpedInOrder({
            ...order,
            menus: updatedMenus,
          });

          const isAllBumped = areAllBumpedInOrder({
            ...order,
            menus: updatedMenus,
          });

          if (isAllBumped || (type === "recall" && !isAnyBumped)) {
            setBlur(null);
          }

          return {
            ...order,
            ...(!isAnyBumpedPrev && {
              bumpedAt: isAnyBumped ? Date.now() : null,
            }),
            menus: updatedMenus,
            isShowAtStart: !isAllBumped,
            isShowAtCooking: isAnyBumped,
          };
        }
        return order;
      }),
    );
  };

  const handleClickActionItem = (params: ActionMenu) => {
    const { order, type, subMenuId, isBumped } = params;

    toast.success(`Item has been ${toastMessageKey[type]} successfully`, {
      position: "top-center",
      icon: <ChecklistIcon />,
    });

    setBlur(null);

    if (type === "print") {
      return;
    }

    if (type === "bump") {
      toggleBumpItemStart(order.id, subMenuId, isBumped);
      return;
    }

    if (type === "recall") {
      toggleBumpItemStart(order.id, subMenuId, false, "recall");
      return;
    }
  };

  const handleClickSummary = (id: number) => {
    setSelectedSummary((prev) => (id === prev ? null : id));
  };

  return (
    <>
      <KitchenTemplates
        header={<KitchenHeader title="KDS Expo View" goto="/" />}
        navigation={
          <KitchenNavigation
            orders={orders}
            selectedSummary={selectedSummary}
            onClickSummary={handleClickSummary}
          />
        }
        className="lg:columns-1"
      >
        <CookingList
          label="ORDER LIST"
          orders={getOrders(orders)}
          onClickActionOrder={handleClickActionMenu}
          onClickActionItem={handleClickActionItem}
          selectedSummary={selectedSummary}
          defaultCols={{
            default: 4,
            1024: 2,
            720: 1,
          }}
        />
      </KitchenTemplates>

      <SettingDialog />
    </>
  );
};

export default Authn(ExpoScreen);
