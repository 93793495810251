import {
  Select,
  SelectItem,
  SelectContent,
  SelectGroup,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { DefaultOptionsType } from "internal/base/type";
import { cn } from "lib/utils";
import { useMemo } from "react";

type SelectIconPropsType = {
  options: Array<DefaultOptionsType & { icon: string }>;
  className?: string;
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  label?: string;
};

export const SelectIcon = (props: SelectIconPropsType) => {
  const { className, options, value, onChange, label, placeholder } = props;
  const icon = useMemo(
    () => options.find((item) => item.value === value)?.icon || "",
    [value, options],
  );

  return (
    <>
      {label && <p className="text-left text-sm mb-1">{label}</p>}
      <Select value={value} onValueChange={(e) => onChange(e)}>
        <SelectTrigger
          className={cn(
            "w-full",
            {
              "text-gray-300": !icon,
            },
            className,
          )}
        >
          <div className="flex gap-6 items-center">
            {value && (
              <span className="flex h-8 w-8 items-center justify-center border">
                <img
                  className="object-cover w-full h-full"
                  src={icon}
                  alt="logo"
                />
              </span>
            )}
            <SelectValue placeholder={placeholder} />
          </div>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {options.map((item, i) => (
              <SelectItem key={i} value={item.value} prefix={item.icon}>
                {item.value}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
};
