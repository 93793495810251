import {
  InstallationStateType,
  useInstallationState,
} from "internal/installation/state";
import { FC, ReactNode, createContext, useContext } from "react";

const InstallationContext = createContext<InstallationStateType>({
  company: "",
  branch: "",
  step: "form",
  openAbort: undefined,
  isSuccess: false,
  mutate: () => undefined,
  renderContent: () => undefined,
  setCompany: () => undefined,
  setBranch: () => undefined,
  onCloseAbort: () => undefined,
  onOpenAbort: () => undefined,
  setStep: () => undefined,
});

export const useInstallationContext = (): InstallationStateType => {
  const context = useContext(InstallationContext);
  if (!context) {
    throw new Error(
      "useInstallationContext must be used within a InstallationProvider",
    );
  }
  return context;
};

type InstallationProviderPropsType = {
  children: ReactNode;
};

export const InstallationProvider: FC<InstallationProviderPropsType> = ({
  children,
}: InstallationProviderPropsType) => {
  return (
    <InstallationContext.Provider value={useInstallationState()}>
      {children}
    </InstallationContext.Provider>
  );
};
