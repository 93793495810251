import { cn } from "lib/utils";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  header: ReactNode;
  navigation: ReactNode;
  className?: string;
}

export default function KitchenTemplates({
  children,
  header,
  navigation,
  className,
}: Props) {
  // const columnClassName = className || "sm:grid-cols-2";

  return (
    <div className="flex bg-[#8C9BAB] h-screen">
      <div className="w-8/12 sm:w-9/12 md:w-10/12 flex flex-col h-full">
        {header}
        <div className={cn("overflow-hidden")}>{children}</div>
      </div>
      <div className="w-4/12 sm:w-3/12 md:w-2/12">{navigation}</div>
    </div>
  );
}
