import React, { createContext, useContext, useState } from "react";

// Buat context untuk blur state
interface BlurContextType {
  on: string;
  off: string;
  isBlur: boolean;
  blurId: number | null;
  setBlur: React.Dispatch<React.SetStateAction<number | null>>;
}

const BlurContext = createContext<BlurContextType | undefined>(undefined);

// Custom hook untuk menggunakan context blur
export const useBlur = () => {
  const context = useContext(BlurContext);
  if (!context) {
    throw new Error("useBlur must be used within a BlurProvider");
  }
  return context;
};

// Provider untuk mengelola state blur
interface BlurProviderProps {
  children: React.ReactNode;
}

export const BlurProvider: React.FC<BlurProviderProps> = ({
  children,
}: BlurProviderProps) => {
  const [blurId, setBlur] = useState<number | null>(null);

  const isBlur = blurId !== null;

  const value = {
    on: isBlur ? 'enableBlur' : '',
    off: isBlur ? 'disableBlur' : '',
    isBlur,
    blurId,
    setBlur,
  };

  return <BlurContext.Provider value={value}>{children}</BlurContext.Provider>;
};
