import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { HeaderItem } from "components/atoms/header";
import { Settings } from "lucide-react";
import { useBlur } from "hooks/UseBlur";
import { cn } from "lib/utils";
import { useEffect, useState } from "react";
import { months } from "lib/global";
import { useSetting } from "hooks/useSetting";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  goto?: string;
}

export default function KitchenHeader(props: Props) {
  const { settingForm, setOpen, updateForm } = useSetting();
  const { title, goto } = props;
  const { on } = useBlur();
  const navigate = useNavigate();

  const [current, setCurrent] = useState({
    time: "",
    date: "",
  });

  useEffect(() => {
    const intervalID = setInterval(() => {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const formattedTime = `${hours}:${minutes}`;

      const month = months[now.getMonth()];
      const dateNumber = now.getDate();
      const year = now.getFullYear();
      const formattedDate = `${dateNumber} ${month} ${year}`;

      setCurrent({
        time: formattedTime,
        date: formattedDate,
      });
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

  return (
    <header
      className={cn(
        on,
        "flex flex-col lg:flex-row gap-3 items-center justify-between bg-gray-800 text-white p-5 ",
      )}
    >
      <div className="flex items-center justify-center gap-4">
        <div className="flex items-center gap-2 text-2xl">
          <h1
            onClick={() => (goto ? navigate(goto) : {})}
            className="font-semibold"
          >
            {title}
          </h1>
          <span className="font-bold">2</span>
        </div>
        <Button
          onClick={() => {
            updateForm(settingForm);
            setOpen(true);
          }}
          className="bg-[#FFFFFF1A] hover:bg-gray-600 py-2 px-3"
        >
          <Settings />
        </Button>
      </div>

      <div className="flex items-center justify-center gap-3 sm:gap-12">
        <HeaderItem label={current.time} value={current.date} />
        <Separator orientation="vertical" className="bg-gray-500 h-6" />
        <HeaderItem label="2:09" value="AVG order time" />
        <Separator orientation="vertical" className="bg-gray-500 h-6" />
        <HeaderItem label="37" value="Active order" />
      </div>
    </header>
  );
}
