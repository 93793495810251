export const toastMessageKey = {
  bump: "bumped",
  recall: "recalled",
  print: "printed",
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Novr",
  "Dec",
];