import { HTMLAttributes } from "react";

export function LogoIcon(props: HTMLAttributes<SVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 89 28"
    >
      <path fill="#414042" d="M84.666 0h-1.68v9.913h1.68V0z"></path>
      <path fill="#414042" d="M78.878 4.127V5.81h9.913V4.127h-9.913z"></path>
      <path
        fill="#F7941D"
        d="M8.899 16.809a1.797 1.797 0 100-3.594 1.797 1.797 0 000 3.594zM8.898 11.855a1.682 1.682 0 01-1.68-1.681V7.826a1.682 1.682 0 013.362 0v2.348c0 .927-.754 1.68-1.682 1.68zM8.898 23.913a1.682 1.682 0 01-1.68-1.68v-2.349a1.682 1.682 0 013.362 0v2.348c0 .928-.754 1.681-1.682 1.681zM5.449 13.272c-.435 0-.87-.174-1.188-.493l-1.653-1.652c-.666-.667-.666-1.74 0-2.377.667-.667 1.74-.667 2.377 0l1.652 1.652c.667.667.667 1.74 0 2.377a1.691 1.691 0 01-1.188.493zM14 21.795c-.434 0-.87-.174-1.188-.493L11.16 19.65c-.667-.666-.667-1.739 0-2.377.666-.666 1.739-.666 2.377 0l1.652 1.653c.666.666.666 1.739 0 2.376a1.621 1.621 0 01-1.189.493zM4.029 16.727H1.681c-.927 0-1.681-.782-1.681-1.71 0-.927.754-1.681 1.681-1.681H4.03c.928 0 1.681.754 1.681 1.681 0 .928-.724 1.71-1.681 1.71zM16.087 16.726H13.74a1.682 1.682 0 010-3.363h2.348c.928 0 1.681.754 1.681 1.681.03.899-.724 1.682-1.68 1.682zM12.319 13.272c-.435 0-.87-.174-1.189-.493-.666-.667-.666-1.74 0-2.377l1.652-1.652c.667-.667 1.74-.667 2.377 0 .667.667.667 1.74 0 2.377l-1.652 1.652a1.691 1.691 0 01-1.188.493zM3.797 21.795c-.435 0-.87-.174-1.189-.493-.666-.666-.666-1.739 0-2.376l1.653-1.653c.666-.666 1.739-.666 2.376 0 .667.667.667 1.74 0 2.377l-1.652 1.652c-.319.348-.753.493-1.188.493z"
      ></path>
      <path
        fill="#414042"
        d="M29.768 19.099a5.418 5.418 0 01-1.42.203c-.464 0-.927-.087-1.362-.232-.435-.174-.84-.377-1.189-.667-.347-.29-.637-.609-.84-1.014a3.788 3.788 0 01-.377-1.305h9.971c-.029-1.826-.145-3.101-.464-3.97-.319-.9-.753-1.624-1.333-2.204-.58-.58-1.246-1.043-2-1.333a7.216 7.216 0 00-2.522-.464c-.985 0-1.913.174-2.753.493-.841.348-1.595.812-2.203 1.42a6.775 6.775 0 00-1.45 2.203c-.347.87-.521 1.826-.521 2.899 0 1.072.174 2.029.521 2.898.348.84.841 1.566 1.479 2.145a6.699 6.699 0 002.232 1.363 8.07 8.07 0 002.811.492c1.102 0 2.058-.174 2.928-.521-.84-.493-1.45-1.392-1.508-2.406zm-4.029-7.681a3.755 3.755 0 012.493-.899c.522 0 .986.087 1.392.26.405.175.724.407.956.697.26.29.435.637.55 1.014.117.377.175.783.175 1.217h-6.696c.058-.927.435-1.68 1.13-2.29z"
      ></path>
      <path
        fill="#F7941D"
        d="M32.522 17.3a1.682 1.682 0 000 3.363 1.682 1.682 0 000-3.362z"
      ></path>
      <path
        fill="#414042"
        d="M37.885 8.176h3.826v1.768h.058c.637-.985 2.26-2.116 4.144-2.116 4 0 6.638 2.899 6.638 6.957 0 3.739-2.319 7.188-6.145 7.188-1.71 0-3.333-.464-4.26-1.768h-.117v7.768h-4.174V8.176h.03zm7.13 10.174c2.087 0 3.333-1.391 3.333-3.42 0-2.03-1.246-3.42-3.333-3.42s-3.333 1.39-3.333 3.42c.029 2.029 1.275 3.42 3.333 3.42zM60.841 7.86c4.174 0 7.478 2.782 7.478 7.072s-3.333 7.072-7.478 7.072c-4.174 0-7.478-2.782-7.478-7.072s3.333-7.073 7.478-7.073zm0 10.492c2.087 0 3.333-1.391 3.333-3.42 0-2.03-1.246-3.42-3.333-3.42s-3.333 1.39-3.333 3.42c0 2.029 1.246 3.42 3.333 3.42zM77.305 12.147c-.609-.754-1.42-1.13-2.406-1.13-.696 0-1.536.318-1.536 1.101 0 2 7.014.319 7.014 5.45 0 3.448-3.333 4.434-6.203 4.434-1.884 0-3.97-.464-5.304-1.826l2.522-2.812c.782.957 1.681 1.479 2.927 1.479.957 0 1.884-.261 1.884-.986 0-2.174-7.014-.319-7.014-5.478 0-3.16 2.84-4.55 5.652-4.55 1.768 0 3.74.434 4.986 1.767l-2.522 2.551z"
      ></path>
    </svg>
  );
}
