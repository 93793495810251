import { SettingFormType, SettingStateType } from "internal/setting/type";

export const defaultSettingForm: SettingFormType = {
  ipAddress: "192.168.1.200",
  itemPrice: false,
  timeWarning: [
    {
      name: "On track",
      minutes: 1,
    },
    {
      name: "Warning",
      minutes: 3,
    },
    {
      name: "Critical",
      minutes: 6,
    },
  ],
  kitchenPrinterStatus: true,
  kitchenPrinterIpAddress: "192.168.1.200",
  checkerPrinterStatus: true,
  checkerPrinterIpAddress: "192.168.1.200",
};

export const defaultSettingState: SettingStateType = {
  open: false,
  settingForm: defaultSettingForm,
  setOpen: () => undefined,
  setSettingForm: () => undefined,
  handleSave: () => undefined,
  isLoading: false,
  form: defaultSettingForm,
  updateForm: () => undefined,
  updateFormTimeWarning: () => undefined,
};
