import { HTMLAttributes } from "react";

export function ChecklistIcon(props: HTMLAttributes<SVGElement>) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8Z"
        fill="#33A353"
      />
      <path
        d="M12 5.33203L6.66667 10.6654L4 7.9987"
        stroke="#E9F7FC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
