import { LogoIcon } from "components/icons";
import { Separator } from "components/ui/separator";
import React from "react";

export const LoginHeader = () => {
  return (
    <div className="border-b p-6 flex bg-white">
      <LogoIcon className="h-6" />
      <Separator className="mx-3" orientation="vertical" />
      <span className="font-bold tracking-widest">KDS</span>
    </div>
  );
};
